var OKTA_FORM_i18n_CONFIG = {};
(function () {
    var
        $oktaFormMessagesTranslationHolder = $('#oktaFormMessages'),
        prioritizeStrategy = $oktaFormMessagesTranslationHolder.data('okta-i18n-prioritize'),
        $oktaFormMessagesVector = $('<div />').html($oktaFormMessagesTranslationHolder.html());
    // if (prioritizeStrategy) {
    //     prioritizeStrategy = $.trim(prioritizeStrategy);
    // }
    $oktaFormMessagesVector.find('[data-okta-i18n-key]').each(function (i, el) {
        var
            $el = $(el),
            i18nLang = $el.data('okta-i18n-lang'),
            i18nKey = $el.data('okta-i18n-key'),
            isCombo = i18nKey === '__combo__';

        ! (i18nLang in OKTA_FORM_i18n_CONFIG) && (OKTA_FORM_i18n_CONFIG[i18nLang] = {});

        if (isCombo) {
            var
                comboSeparator = '=',
                comboContent = $el.html(),
                comboContentStock = comboContent.split('\n');

            comboContentStock.forEach(function (content) {
                content = $.trim(content);
                if (! content || content.lastIndexOf('#', 0) === 0) {
                    return;
                }
                var splits = content.split(comboSeparator);
                if (splits.length >= 2) { // Everything is as expected
                    var firstItemInSplit = splits.shift();
                    i18nKey = $.trim(firstItemInSplit);
                    OKTA_FORM_i18n_CONFIG[i18nLang][i18nKey] = $.trim(splits.join(comboSeparator));
                }
            });
        }
        else {
            OKTA_FORM_i18n_CONFIG[i18nLang][i18nKey] = $.trim($el.html());
        }
    });
})();

var OKTA_FORM_UI = {
    useMotionPointTranslations: true,
    renderPointSelector: '#okta-login-container',
    selectors: {
        submitButton: '#okta-signin-submit',
        hookError: '#hookErrorMessage',
        sessionExpired: '#sessionExpired',
        isInconsistentAppUsageContent: '#isInconsistentAppUsageContent',
        oktaErrorContainer: '.o-form-error-container',
        helpLinksContainer: '#help-links-container',
        oktaSignInUsername: '#okta-signin-username',
        oktaSignInPassword: '#okta-signin-password',
        accountRecoveryUsername: '#account-recovery-username',
        notesContainer: '#login-notes-container',
        notesContainerForgotUsernameCloneable: '#login-notes-container .forgot-username',
        notesContainerForgotPasswordCloneable: '#login-notes-container .forgot-password',
        forgotUsernameRequestTrigger: '.forgot-password .button-primary',
        forgotPasswordContainer: '.auth-content .o-form-content'
    },
    initialize: function (widget) {
        this.translationSource =
            OKTA_FORM_CONFIG.i18n[
                this.useMotionPointTranslations
                    ? DEFAULT_LANGUAGE
                    : (USER_LANGUAGE in OKTA_FORM_CONFIG.i18n)
                    ? USER_LANGUAGE
                    : DEFAULT_LANGUAGE
                ];

        if (! this.widget) {
            this.widget = widget;
            this.widget.on('afterError', this.onAfterError.bind(this));
        }

        Object.keys(this.selectors).forEach(function (element) {
            this['$' + element] = $(this.selectors[element]);
        }, this);

        if (this.$sessionExpired.length) {
            this.toggleErrorMessage(this.$sessionExpired, true)
        }
    },
    onAfterError: function (context, error) {
        this.toggleSubmitTrigger(false, context.controller);
        if (context.controller === 'forgot-password') {
            this.forgotPasswordErrorTriggered = true;
        }
        console.debug(error);
    },
    toggleSubmitTrigger: function (bool, flow) {
        var
            flow = flow || 'primary-auth',
            submitTrigger = ({
                'primary-auth': this.$submitButton,
                'forgot-password': this.$fakeForgotUsernameRequestTrigger
            })[flow],
            triggersToDisable = ({
                'primary-auth': [
                    this.$oktaSignInUsername,
                    this.$oktaSignInPassword
                ],
                'forgot-password': [
                    this.$accountRecoveryUsername
                ]
            })[flow],
            triggersLabelText = ({
                'primary-auth': {
                    processing: 'custom.sendingText',
                    initial: 'primaryauth.submit'
                },
                'forgot-password': {
                    processing: 'custom.sendingText',
                    initial: 'password.forgot.sendEmail'
                }
            })[flow];

        triggersToDisable.push(submitTrigger);

        var method = submitTrigger.is('a') ? 'html' : 'val';
        submitTrigger[method](this.translationSource[triggersLabelText[bool ? 'processing' : 'initial']]);
        $.each(triggersToDisable, function (i, $el) {
            $el.toggleClass('disabled', bool).prop('disabled', bool);
        });
        return this;
    },
    toggleErrorMessage: function (msg, skipHookErrorCloning) {
        this.$oktaErrorContainer.empty();
        var
            bool = !! msg,
            skipHookErrorCloning =
                typeof skipHookErrorCloning !== 'undefined'
                    ? !! skipHookErrorCloning
                    : false,
            $appendable = this.$hookError.clone();

        bool && this.$oktaErrorContainer.append(
            (skipHookErrorCloning && $appendable.find('p').html(msg) && $appendable) || $appendable
            // skipHookErrorCloning
                // ? $appendable.find('p').html(msg) && $appendable // msg
                // : $appendable
        );
        return this;
    },
    updateUsernameFormField: function (newUsername) {
        this.$oktaSignInUsername
            .val($.trim(newUsername))
            .get(0)
            .dispatchEvent(this._generatedInputEvent());
        return this;
    },
    updateLoginFormFields: function (newUsername) {
        this.$oktaSignInUsername
            .val($.trim(newUsername))
            .get(0)
            .dispatchEvent(this._generatedInputEvent());
        this.$oktaSignInPassword
            .val('')
            .get(0)
            .dispatchEvent(this._generatedInputEvent());
        return this;
    },
    _generatedInputEvent: function(){
        return new Event('input', { bubbles: true });
    }
};

var oktaSignIn = new OktaSignIn(_.extend(OKTA_FORM_CONFIG, {
    i18n: OKTA_FORM_i18n_CONFIG,
    features: {
        showPasswordToggleOnSignInPage: true,
        processCreds: function (creds, callback) {
            OKTA_FORM_UI
                .handlePassReveal(false)
                .toggleSubmitTrigger(true);

            App.Requests.send({
                requestEndpoint: CONTEXT_PATH + '/login/user_sync_check',
                data: {
                    user: creds.username,
                    password: creds.password
                },
                requestType: 'POST'
            })
                .done(function (response) {
                    if (response.status === 1) {
                        callback();
                    }
                    else if ([-1, -2, -4].indexOf(response.status) !== -1) {
                        OKTA_FORM_UI
                            .toggleSubmitTrigger(false)
                            .toggleErrorMessage(response.statusMessage);
                    } else if ([-3].indexOf(response.status) !== -1) {
                        OKTA_FORM_UI
                            .toggleSubmitTrigger(false)
                            .toggleErrorMessage(response.statusMessage, true)
                            .updateLoginFormFields(response.newLogin);
                    }
                })
                .fail(function (error) {
                    OKTA_FORM_UI.toggleSubmitTrigger(false)
                        .toggleErrorMessage("Something went wrong, please try again later");
                });
        }
    }
}));

$(function () {
    oktaSignIn
        .on('afterRender', function (widget, context) {
            this.initialize(widget);

            var _$inputElements = $(this.renderPointSelector).find('.' + context.controller + ' :input');

            if (_$inputElements.length) {
                _$inputElements.each(function (i, el) {
                    var $el = $(el);
                    if ($el.prop('placeholder') === '') {
                        $el.prop('placeholder', ' ')
                    }
                })
            }

            if (context.controller === 'primary-auth') {
                this.$helpLinksContainer.css('display', 'flex');
                this.$notesContainer
                    .show()
                    .find('.forgot-links')
                    .clone()
                    .appendTo('.' + context.controller + ' .o-form-fieldset-container');

                var
                    $passwordToggleContainer = $('.' + context.controller + ' .password-toggle'),
                    $passwordToggleActionShow = $passwordToggleContainer.find('.button-show'),
                    $passwordToggleActionHide = $passwordToggleContainer.find('.button-hide');

                $passwordToggleActionShow.text(this.translationSource['primaryauth.revealpass.show']);
                $passwordToggleActionHide.text(this.translationSource['primaryauth.revealpass.hide']);

                /* Adding Accessibility Aria to Title */
                var _$formTitleElement = $(this.renderPointSelector)
                    .find('.' + context.controller + ' .okta-form-title');

                if (_$formTitleElement.length) {
                    _$formTitleElement.attr('aria-hidden', 'true');

                    var $hiddenLabel = $('<div>', {
                        id: "hiddenFormTitleLabel_" + new Date().getTime(),
                        text: this.translationSource['primaryauth.titleAriaLabel'],
                        class: 'visually-hidden-text'
                    });

                    _$formTitleElement.after($hiddenLabel);

                    _$formTitleElement.attr('aria-labelledby', $hiddenLabel.attr('id'));
                }
                /* Adding Accessibility Aria to Title */
            }
            else {
                if (context.controller === 'forgot-password') {
                    this.$notesContainer.show();
                    this.$fakeForgotUsernameRequestTrigger = this.$forgotUsernameRequestTrigger.clone();
                    this.$forgotUsernameRequestTrigger
                        .before(this.$fakeForgotUsernameRequestTrigger)
                        .hide();

                    this.$fakeForgotUsernameRequestTrigger.on('click', function (e) {
                        e.preventDefault();
                        var value = $.trim(this.$accountRecoveryUsername.val());
                        if (value === '') {
                            this.$accountRecoveryUsername.val('');
                            this.$forgotUsernameRequestTrigger.get(0).click();
                            return;
                        }

                        this.toggleErrorMessage(false);
                        this.toggleSubmitTrigger(true, 'forgot-password');

                        App.Requests
                            .send({
                                requestEndpoint: CONTEXT_PATH + '/login/get_forgot_password_flow?user=' + value
                            })
                            .done(function (response) {
                                ({
                                    VG_FORGOT_PASSWORD: function () {
                                        window.location.href = CONTEXT_PATH + '/login/forgot_password?reset'
                                    },
                                    OKTA_FORGOT_PASSWORD: function (isInconsistentAppUsage) {
                                        if (isInconsistentAppUsage) {
                                            this.$forgotPasswordContainer.html(this.$isInconsistentAppUsageContent.clone())
                                            var backLink = document.getElementsByClassName('js-back')[0];
                                            $(backLink).hide()
                                            $('.fake-back-button').on('click', function (e) {
                                                e.preventDefault()
                                                backLink.click()
                                            })
                                            return
                                        }
                                        if (this.forgotPasswordErrorTriggered) {
                                            this.toggleSubmitTrigger(false, 'forgot-password'); // OKTA's 'afterError' fires only ones :(. We have to undisable trigger on a second attempt
                                        }

                                        this.$forgotUsernameRequestTrigger.get(0).click();
                                    }
                                })[response.flow].bind(this)(response.isInconsistentAppUsage);
                            }.bind(this))
                            .fail(function (errors) {
                                this.toggleSubmitTrigger(false, 'forgot-password');
                                if (_.isArray(errors) && errors.length && _.isObject(errors[0])) {
                                    // errors = [{
                                    //     code: 100,
                                    //     message: "TEXT"
                                    // }]
                                    this.toggleErrorMessage(JSON.stringify(errors[0]), true);
                                }
                            }.bind(this));
                    }.bind(this));
                }
                else if (context.controller === 'password-reset-email-sent') {

                }
                else {
                    this.$notesContainer.hide();
                }
            }

            if (OKTA_FORM_CONFIG.predefinedUsername !== undefined && OKTA_FORM_CONFIG.predefinedUsername !== "") {
                OKTA_FORM_UI.updateUsernameFormField(OKTA_FORM_CONFIG.predefinedUsername)
            }
        }.bind(OKTA_FORM_UI, oktaSignIn))
        .renderEl({
            el: OKTA_FORM_UI.renderPointSelector
        });
});
